import React, { useEffect, useState } from "react";
import { API } from "../../services/api";
import ListaProdutosMercadoLivre from "../../ListaProdutosMercadoLivre";
import Swal from "sweetalert2";
import {
  FaEye,
  FaRegUserCircle,
  FaTrash,
  FaUser,
  FaUserAlt,
  FaUserAltSlash,
  FaUserCircle,
  FaUserPlus,
} from "react-icons/fa";
import Loading from "../Loading/Loading";

const LoginButton: React.FC = () => {
  const [allUsers, setAllUsers] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>({});
  const [isLoadingDelClient, setIsLoadingDelClient] = useState<any>({});

  const handleLogin = async () => {
    const texto =
      "http://auth.mercadolivre.com.br/authorization?response_type=code&client_id=1596222345102284&redirect_uri=https://precos.integracomm.com.br";

    try {
      await navigator.clipboard.writeText(texto);
      Swal.fire({
        title: "Link copiado!",
        text: "O link foi copiado para a área de transferência.",
        icon: "success",
        showCloseButton: true,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Erro ao copiar texto:", error);
    }
    // try {
    //   const result = await API.post("/login");
    //   window.location.href = result.data.linkRedirect;
    // } catch (error) {
    //   console.error("Erro ao fazer login:", error);
    // }
  };

  async function contaCompartilhada(user_id: string) {
    setIsLoading((prevLoadingButtons: any) => ({
      ...prevLoadingButtons,
      [user_id]: true,
    }));
    try {
      if (user_id) {
        const resultLogin = await API.post("/listarLogin", {
          userid: user_id,
        });

        if (resultLogin.data.data.length > 0) {
          const result = await API.post("/getCode", {
            token_refresh: resultLogin?.data?.data[0].refresh_token,
            usar_promocao: resultLogin?.data?.data[0].usar_promocao,
          });

          localStorage.setItem("USER_ID", resultLogin.data.data[0].user_id);
          localStorage.setItem(
            "ACCESS_TOKEN",
            resultLogin.data.data[0].access_token
          );
          localStorage.setItem(
            "REFRESH_TOKEN",
            resultLogin?.data?.data[0].refresh_token
          );
          window.location.href = "/listaProdutos";
        } else {
          await Swal.fire({
            title: "Opss...",
            text: "Não foi encontrado conta com número " + user_id + "!",
            icon: "error",
            showCloseButton: true,
            confirmButtonText: "OK",
          });
        }
      }
    } finally {
      setIsLoading((prevLoadingButtons: any) => ({
        ...prevLoadingButtons,
        [user_id]: false,
      }));
    }
  }

  async function listAllUsers() {
    const resultLogin = await API.post("/listarLogin", {});
    setAllUsers(resultLogin.data.data);
  }

  async function deletarCliente(user_id: string, refreshToken: string) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Esta ação não pode ser desfeita!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, continuar!",
      cancelButtonText: "Não, cancelar!",
    }).then(async (resultAlert: any) => {
      if (resultAlert.isConfirmed) {
        setIsLoadingDelClient((prevLoadingButtons: any) => ({
          ...prevLoadingButtons,
          [user_id]: true,
        }));

        try {
          const result = await API.post("/getCode", {
            token_refresh: refreshToken,
          });

          const resultDelete = await API.post("/revogarAutorizacao", {
            userId: user_id,
            access_token: JSON.parse(result.data.result.body).data.access_token,
          });
          if (resultDelete.status === 200) {
            const deleteAll = await API.post("/deleteAllInfo", {
              user_id,
            });

            await listAllUsers();
          }
        } finally {
          setIsLoadingDelClient((prevLoadingButtons: any) => ({
            ...prevLoadingButtons,
            [user_id]: false,
          }));
        }
      }
    });
  }

  useEffect(() => {
    listAllUsers();
    if (localStorage.getItem("LOGIN") === "false") {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="relative">
      {/* Verifica se allUsers existe e não está vazio */}
      {!allUsers || allUsers.length === 0 ? (
        // Exibe o spinner de loading
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        // Renderiza a lista de usuários
        <div className="flex flex-wrap justify-center gap-4 max-w-[calc(8*8rem)] max-h-[calc(4*8rem)] overflow-auto">
          {allUsers.map((item: any) => (
            <div
              title={item.cliente_nome}
              className="flex flex-col items-center justify-center p-4 border-2 border-gray-300 rounded-lg w-32 h-32 overflow-hidden"
            >
              <div className="text-xs font-bold text-center mb-4">
                {item.cliente_nome.length > 12
                  ? item.cliente_nome.substring(0, 12) + "..."
                  : item.cliente_nome}
              </div>
              <div className="mb-4">
                <FaUser style={{ fontSize: 30 }} />
              </div>
              <div className="flex space-x-2 justify-center">
                <button
                  onClick={() => contaCompartilhada(item.user_id)}
                  className="bg-blue-500 text-white font-bold py-1 px-2 rounded hover:bg-blue-700"
                >
                  {isLoading[item.user_id] ? (
                    <Loading />
                  ) : (
                    <FaEye style={{ fontSize: 13 }} />
                  )}
                </button>
                <button
                  onClick={() =>
                    deletarCliente(item.user_id, item.refresh_token)
                  }
                  className="bg-red-500 text-white font-bold py-1 px-2 rounded hover:bg-red-700"
                >
                  {isLoadingDelClient[item.user_id] ? (
                    <Loading />
                  ) : (
                    <FaTrash style={{ fontSize: 13 }} />
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Botões posicionados no canto superior direito da tela */}
      <div className="fixed top-0 right-0 mt-2 mr-2 flex space-x-2">
        <button
          onClick={handleLogin}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
        >
          Link para Novo Cliente
        </button>
      </div>
    </div>
  );
};

export default LoginButton;
